import React from 'react';
import { PageProps } from 'gatsby';
import { LayoutOnBoarding, SEO, CategoryView } from '@components';
import { useAuth } from '@hooks';
import { useMediaQuery } from '@material-ui/core';
import { LayoutMobileInvestments } from '@components/Investments/Layout';
import { getUserMissions } from '@apollo';
import { MissionsInfo } from '@data';

const CompletarPerfil: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery('(max-width: 1240px)');
  const { userMissions } = getUserMissions();

  const missions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.ProfileCategory.name
  );

  return (
    <>
      <SEO
        title="Rokipedia - Completa tu perfil"
        description="Gana Roks al completar tu perfil! Incluyendo tus datos tendrás una experiencia más completa y podrás invertir con nosotros"
      />
      {!isMobile ? (
        <LayoutOnBoarding activeRokinpedia>
          <CategoryView
            missions={missions}
            categoryInfo={MissionsInfo.ProfileCategory}
          />
        </LayoutOnBoarding>
      ) : (
        <LayoutMobileInvestments activeIncent>
          <CategoryView
            missions={missions}
            categoryInfo={MissionsInfo.ProfileCategory}
          />
        </LayoutMobileInvestments>
      )}
    </>
  );
};

export default CompletarPerfil;
